const Contact = () => {
    return (
        <>
            <section>
                <div>Contacts</div>
            </section>
        </>
    )
}

export default Contact;