// import instagramIcon from "../images/insta-icon.png"
import {FaInstagram, FaTwitter, FaFacebook, FaCoffee } from "react-icons/fa";
const Footer = () => {
  return (
    <footer>
        <div className="social">
            <a href="#"><FaInstagram/></a>
            <a href="#"><FaTwitter/></a>
            <a href="#"><FaFacebook/></a>
            <a href="#"><FaCoffee/></a>
        </div>
    </footer>
  )
}

export default Footer