import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="navbar">
        <NavLink 
        className={({isActive}) => isActive ? "active link" : "active"}
           to="/">Home</NavLink>
        {/* <NavLink   to="about">About</NavLink> */}
        <NavLink   to="/blog">Blog</NavLink>
        <NavLink to="/contact">Contact</NavLink>
    </nav>
  )
}

export default Navbar