import blogPosts from "../data";
import { Link } from "react-router-dom"
const Blog = () => {
    return blogPosts.map(post => {
        return (
        <>
            <article key={post.id}>
                <Link to={`/blog/post${post.id}`}><h2>{post.title}</h2></Link>
                <p>{post.body}</p>
            </article>
        </>
        )
    })
}

export default Blog