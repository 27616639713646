import { Link, useParams } from "react-router-dom"
import blogPosts from "../data"

const BlogPost = () => {
    const {postId} = useParams()

    const post = blogPosts.find(post => postId === `post${post.id}`)
    const {title, body} = post;
    return (
        <>
            <h3>{title}</h3>
            <p>{body}</p>
            <Link to="/blog">Back to Blog</Link>
        </>
    )
}

export default BlogPost