const blogPosts = [
    {
        id: 1,
        title: "Straight outta Mirkhood",
        body: `Blah blah`      
    },
    {
        id: 2,
        title: "Other Title",
        body: "blah blah blah blah blah blah"
    },
    {
        id: 3,
        title: "Other Title",
        body: "blah blah blah blah blah"
    }
]

export default blogPosts;