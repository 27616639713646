import React from "react";
import "./App.css"
import SharedLayout from "./components/SharedLayout";
import Home from "./components/Home";
import About from "./components/About";
import Blog from "./components/Blog";
import BlogPost from "./components/BlogPost";
import Contact from "./components/Contact";
import Footer from "./components/Footer"
import {BrowserRouter, Routes, Route} from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<SharedLayout />}>
              <Route index element={<Home />}/>
              {/* <Route path="about" element={<About />}/> */}
              <Route path="blog" element={<Blog />}/>
                <Route path="blog/:postId" element ={<BlogPost />}/>
              <Route path="contact" element={<Contact />} />
            </Route>
        </Routes>
        <Footer />
    </BrowserRouter>
    

  );
}

export default App;